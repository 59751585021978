@font-face {
    font-family: Montserrat;
    src: url("fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
    font-family: NotoSans;
    src: url("fonts/NotoSans-Light.ttf");
}

:root {
    --logo-color-blue: #5e8b96;
    --logo-color-brown: #96695e;
    --offer-card-color-blue: rgb(137, 168, 177);
    --offer-card-color-brown: #aa867d;
    --main-background-color1: #fff7f7;
    --main-background-color2: #b3b1b1e5;
    --offer-card-list-color: rgba(255, 255, 255, 0.788);
    --contact-background-color: rgba(255, 255, 255, 0.493);
    --contact-background-list-color: #d3dadb52;
    --color-grey1: #747f7e;
    --color-grey2: #babbba;
    --color-grey3: #d2dadb;
    --color-brown1: #b08e85;
    --color-brown2: #c1a4a4;
    --color-brown3: #d3beb7;
    --color-brown4: #dccccc;
    --color-brown5: #e1d6d0;
    --color-blue1: #9ab4bc;
}

.MainLayout {
    background-image: linear-gradient(180deg, #fff7f7, #b3b1b1e5);
}

.logo-text {
    font-family: "Montserrat", "Lato", sans-serif;
    color: #747f7e;
    font-size: 10px;
}

.navbar-link {
    font-family: NotoSans, sans-serif;
    font-size: 16px;
    color: var(--logo-color-blue);
}

.nav-border-bottom:hover {
    border-bottom: 1px solid var(--logo-color-brown);
}

.navbar {
    background-color: #f6f4f1;
}

.card-title {
    font-family: "Montserrat", "Lato", sans-serif;
}

.card-img {
    opacity: 0.5;
}
.card-img-adjust {
    height: fit-content;
    width: auto;
    overflow: hidden;
}

.card-text {
    font-family: "Montserrat", "Lato", sans-serif;
}

.card-background1 {
    background-color: var(--color-brown5);
}

.card-background2 {
    background-color: var(--color-grey3);
}

.footer {
    background-color: #bebcbc;
    font-size: 0.75em;
}

.footer-link {
    font-family: NotoSans, sans-serif;
    font-size: 12px;
    color: var(--logo-color-blue);
}

.footer-border-top:hover {
    border-top: 1px solid var(--logo-color-brown);
}

.copyright-text {
    font-size: 0.75em;
}

.offer-title {
    font-family: "Montserrat", "Lato", sans-serif;
}

.color-offer-card {
    background-color: var(--offer-card-color-blue);
}

.color-offer-card2 {
    background-color: var(--offer-card-color-brown);
}

.color-offer-list {
    background-color: var(--offer-card-list-color);
}

.list-font {
    font-family: "NotoSans", sans-serif;
}

.maps-color {
    background-color: var(--contact-background-color);
}

.contact-card-background2 {
    background-color: var(--main-background-color1);
}

.contact-card-background {
    background-color: var(--contact-background-list-color);
}
